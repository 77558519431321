/*
 * Copyright (C) 2019. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */

import moment from 'moment'

export default class D3Event {
  constructor (event, begin) {
    this._event = event // parent event
    this._begin = moment(begin)
    this._visible = null
  }

  getTimeDiff (time) {
    return moment(time).diff(this.begin)
  }

  get visible () {
    return this._visible
  }

  set visible (state) {
    this._visible = state
  }

  get d3visibleState () {
    return this._visible ? 'visible' : this.hasEventBox ? 'visible' : 'hidden'
  }

  get event () {
    return this._event
  }

  get uuid () {
    return this._event.uuid
  }

  get begin () {
    return this._begin
  }

  set begin (time) {
    this._begin = moment.utc(time)
  }

  get group () {
    return this.event.group
  }

  get end () {
    if ('duration' in this.event.argsMixins) {
      let end = moment(this.begin).add(this.event.argsMixins.duration, 'seconds')
      return end
    }
    return false
  }

  get hasEventBox () {
    return this.nextEnd || this.prevBegin || this.event.argsMixins.duration
  }

  get hasDurationArgs () {
    return 'duration' in this.event.argsMixins
  }

  get nextEnd () {
    return this._event.nextDiff
      ? moment(this.begin).add(this._event.nextDiff)
      : null
  }

  get prevBegin () {
    return this._event.prevDiff
      ? moment(this.begin).subtract(this._event.prevDiff)
      : null
  }

  hasRightBoundingBox (range) {
    return this.nextEnd && this.nextEnd > range
  }

  hasLeftBoundingBox (range) {
    return this.prevBegin && this.prevBegin < range
  }
}
